import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { Issue } from '../../types/issue';
import { ChangeEvent, useState } from 'react';

export interface IssueMergeDialogProps extends ModalProps {
  onMerge: (mergeId: string | undefined, deleteSource: boolean) => void;
  issues: Array<Issue>;
}

function IssueMergeDialog(props: IssueMergeDialogProps) {
  const [mergeId, setMergeId] = useState('');
  const [deleteSource, setDeleteSource] = useState(true);
  const modelProps: ModalProps = { ...props };
  delete modelProps.onMerge;
  delete modelProps.onCancel;

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setMergeId(event.target.value);
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteSource(event.target.checked);
  };

  return (
    <Modal {...modelProps} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Merge Selected Observations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="merge_id">Merge Into ID</Form.Label>
            <Form.Select id="merge_id" name="type" value={mergeId} onChange={handleSelectChange}>
              <option value="">Select an ID</option>
              {props.issues.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.id}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Check id="delete_source_records" label="Delete Source Records" onChange={handleChecked} checked={deleteSource} />
          </Form.Group>
        </Form>

        <ul>
          {props.issues
            .filter((issue) => issue.id !== mergeId)
            .map((issue) => (
              <li key={`li${issue.id}`}>
                {issue.id} - {issue.type} - {issue.severity}
              </li>
            ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onMerge(mergeId, deleteSource)} disabled={mergeId === ''}>
          Continue
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IssueMergeDialog;
