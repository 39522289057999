import { ChangeEvent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface ConfigurationDialogProps extends ModalProps {
  device: string | undefined;
  confirm: () => void;
}

function ConfigurationDialog(props: ConfigurationDialogProps) {
  const [name, setName] = useState(props.device);
  const modelProps: ModalProps = { ...props };
  delete modelProps.device;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Modal {...modelProps} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" readOnly={true} value={name} onChange={handleChange}></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={props.confirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfigurationDialog;
