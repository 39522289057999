import { ChangeEvent, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../App.css';
import AuthHeader from '../components/site-header';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import { Api } from '../services/api';
import Table from 'react-bootstrap/Table';
import LeftAdminNav from '../components/left-admin-nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { Issue, Image, UpdateIssue, UpdateIssuesStatus, observationTypes, Project } from '../types/issue';
import IssueEditForm from '../components/observation/issue-edit-form';
import IssueImageView from '../components/observation/issue-image-view';
import 'rsuite/dist/rsuite.min.css';
import IssueConfirmDialog from '../components/observation/issue-confirm-dialog';
import ErrorDialog from '../components/error-dialog';
import IssuesEditDialog from '../components/observation/issues-edit-dialog';
import { SessionContext } from '../context/session-context';
import { useHotkeys } from 'react-hotkeys-hook';
import IssueMergeDialog from '../components/observation/issues-merge-dialog';

type SortField = 'type' | 'severity' | 'summary' | 'workOrder' | 'id' | 'creationDate' | 'modifiedDate' | 'status';
type SortOrder = 'asc' | 'desc';

function Observations() {
  useHotkeys('ctrl+r', () => refresh());
  const sessionContext = useContext(SessionContext);
  const api = Api.getInstance();
  const { projectId } = useParams();
  const history = useNavigate();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [loadedProjectId, setLoadedProjectId] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasProjects, setHasProjects] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editIssue, setEditIssue] = useState<Issue>({
    id: '',
    projectId: '',
    type: '',
    severity: 0,
    summary: '',
    workOrder: '',
    creationDate: '',
    modifiedDate: '',
    status: '',
    images: [],
  });
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();
  const [showDeleteItemConfirmation, setShowDeleteItemConfirmation] = useState<boolean>(false);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [project, setProject] = useState<Project | undefined>();
  const [issues, setIssues] = useState<Array<Issue> | null>(null);
  const [data, setData] = useState<Array<Issue> | null>(null);
  const [selected, setSelected] = useState<Map<string, Issue>>(new Map());
  const [status, setStatus] = useState<string>('unapproved');
  const [type, setType] = useState<string>('');
  const [severity, setSeverity] = useState<string>('');
  const [pages, setPages] = useState<Array<number> | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortField, setSortField] = useState<SortField>('creationDate');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [showIssuesEditDialog, setShowIssuesEditDialog] = useState<boolean>(false);
  const [showMergeDialog, setShowMergeDialog] = useState<boolean>(false);
  const [showConfirmIssuesDialog, setShowConfirmIssuesDialog] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState<{ message: string; title: string }>({ message: '', title: '' });
  const [selectedOperation, setSelectedOperation] = useState<'approved' | 'unapproved' | 'deleted'>('approved');

  function hasAccessToAdmin() {
    const email = sessionContext.details.email;
    return (email !== undefined && email.endsWith('onsightops.com')) || sessionContext.details.isAdmin === 'true';
  }

  async function getProjects() {
    if (!hasProjects) {
      const projects = (await api.getProjects()) as Array<Project>;
      setProjects(projects);
      setProject(projects.find((p) => p.id === projectId));
      setHasProjects(true);
    }
  }

  async function getIssues(projectId: string | undefined, status: string, dateRange: [Date, Date] | null, type: string, severity: string) {
    if (projectId === undefined) return;
    try {
      const issues = (await api.getIssues(projectId, status, dateRange, type, severity)) as Array<Issue>;
      setCurrentPage(1);
      setSortField('creationDate');
      setSortOrder('desc');
      setIssues(issues);
      setSelected(new Map());
      if (pageSize < issues.length) {
        const pagesArray = [];
        const pages = Math.ceil(issues.length / pageSize);
        console.log('Pages calculation', issues.length, pageSize, pages);
        const start = 1;
        const pagesInArray = Math.min(start + 6, pages);
        for (let number = start; number <= pagesInArray; number++) {
          pagesArray.push(number);
        }
        setLastPage(pages);
        console.log('Last Page set to', lastPage);
        const index = currentPage - 1;
        const last = index + pageSize;
        setData(issues.slice(index, last));
        setPages(pagesArray);
      } else {
        setLastPage(1);
        setPages(null);
        setData(issues);
      }
    } catch (error) {
      console.error({ message: 'Error occurred getting issues', error });
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  async function refresh() {
    if (projectId === undefined) return;
    console.log('Refreshing');
    const issues = (await api.getIssues(projectId, status, dateRange, type, severity)) as Array<Issue>;
    const field = sortField;
    const order = sortOrder;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sorted = [...issues].sort((a: any, b: any) => {
      if (a[field] === null) return 1;
      if (b[field] === null) return -1;
      if (a[field] === b[field]) return 0;
      // return (a[field] < b[field] ? -1 : 1) * (order === 'asc' ? 1 : -1);
      return (
        a[field].toString().localeCompare(b[field].toString(), 'en', {
          numeric: true,
        }) * (order === 'asc' ? 1 : -1)
      );
    });
    console.log('sorted', sorted);
    setIssues(sorted);
    const pages = Math.ceil(sorted.length / pageSize);
    setSelected(new Map());
    setLastPage(pages);
    if (currentPage > pages) {
      fetchPage(pages, sorted);
    } else {
      fetchPage(currentPage, sorted);
    }
  }

  function onProjectChange(projectId: string) {
    history(`/admin/observations/${projectId}`);
    setProject(projects.find((p) => p.id === projectId));
    getIssues(projectId, status, dateRange, type, severity);
  }

  function handleChecked(e: ChangeEvent<HTMLInputElement>, issue: Issue) {
    if (!e.target.checked) {
      setSelected((prev) => {
        const map = new Map(prev);
        map.delete(issue.id);
        return map;
      });
    } else {
      setSelected((prev) => {
        const map = new Map(prev);
        map.set(issue.id, issue);
        return map;
      });
    }
  }

  function changeStatus(e: React.ChangeEvent<HTMLSelectElement>) {
    const status = e.target.value.toLowerCase();
    setStatus(status);
    getIssues(projectId, status, dateRange, type, severity);
  }

  function changeType(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    console.log('Changing type', value);
    setType(value);
    getIssues(projectId, status, dateRange, value, severity);
  }

  function changeSeverity(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    console.log('Changing severity', value);
    setSeverity(value);
    getIssues(projectId, status, dateRange, type, value);
  }

  function changeDateRange(value: DateRange | null) {
    console.log('Changing date range', value);
    setDateRange(value);
    getIssues(projectId, status, value, type, severity);
  }

  function handleAllChecked(e: ChangeEvent<HTMLInputElement>) {
    const map = new Map<string, Issue>();
    if (!isChecked()) {
      issues?.forEach((issue) => map.set(issue.id, issue));
    }
    setSelected(map);
  }

  function handleSorting(field: string, order: 'asc' | 'desc') {
    if (issues !== null) {
      console.log('sortField', field);
      console.log('sortOrder', order);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sorted = [...issues].sort((a: any, b: any) => {
        if (a[field] === null) return 1;
        if (b[field] === null) return -1;
        if (a[field] === b[field]) return 0;
        // return (a[field] < b[field] ? -1 : 1) * (order === 'asc' ? 1 : -1);
        return (
          a[field].toString().localeCompare(b[field].toString(), 'en', {
            numeric: true,
          }) * (order === 'asc' ? 1 : -1)
        );
      });
      console.log('sorted', sorted);
      setIssues(sorted);
      const index = currentPage - 1;
      const last = index + pageSize;
      setData(sorted.slice(index, last));
    }
  }

  function sortTable(field: SortField) {
    const order = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    console.log('order', order, sortOrder);
    console.log('field', field, sortField);
    setSortField(field);
    setSortOrder(order);
    handleSorting(field, order);
  }

  function isChecked(): boolean {
    const isChecked = selected.size > 0 && selected.size === (issues?.length ?? 0);
    if (checkboxRef.current !== null && selected.size > 0 && !isChecked) {
      checkboxRef.current.indeterminate = true;
    } else if (checkboxRef.current !== null) {
      checkboxRef.current.indeterminate = false;
    }
    return isChecked;
  }

  function isButtonsDisabled(): boolean {
    return selected.size === 0;
  }

  function isMergeButtonDisabled(): boolean {
    return selected.size <= 1;
  }

  function fetchPage(page: number, issueList?: Array<Issue>) {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    if (issueList !== undefined) {
      setData(issueList?.slice(startIndex, endIndex) ?? null);
    } else {
      setData(issues?.slice(startIndex, endIndex) ?? null);
    }
    setCurrentPage(page);
    if (issues !== null && pageSize < issues.length) {
      const pagesArray = [];
      const pages = Math.ceil(issues.length / pageSize);
      console.log('Pages calculation', issues.length, pageSize, pages);
      let start = page;
      if (page > 3) {
        start = page - 3;
      } else {
        start = 1;
      }
      const pagesInArray = Math.min(start + 6, pages);
      for (let number = start; number <= pagesInArray; number++) {
        pagesArray.push(number);
      }
      setPages(pagesArray);
    }
  }

  function doEditIssue(issue: Issue) {
    setEditIssue(issue);
    setShowEditModal(true);
  }

  async function doSelectImage(image: Image) {
    setSelectedImage(undefined);
    setShowImageModal(true);
    if (image.url === undefined) {
      try {
        const response = await api.getImageUrl(image.bucket, image.path);
        image.url = response.url;
        setSelectedImage(image);
      } catch (error) {
        console.error('Error occurred getting signed url', error);
        setError((error as Error).message ?? 'An unexpected error has occurred');
        setShowError(true);
      }
    } else {
      setSelectedImage(image);
    }
  }

  function doConfirmDeleteEditIssue() {
    setShowDeleteItemConfirmation(true);
  }

  /**
   * Marks a single issue as deleted.
   */
  async function doDeleteEditIssue() {
    setShowDeleteItemConfirmation(false);
    setShowEditModal(false);
    try {
      const update: UpdateIssuesStatus = {
        ids: [editIssue.id],
        value: 'deleted',
      };
      await api.updateIssuesStatus(update);
      await refresh();
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  /**
   * Updates an individual Issue.
   * @param issue The new values for the issue.
   */
  async function doUpdateEditIssue(issue: Issue) {
    setShowEditModal(false);
    try {
      const update: UpdateIssue = {
        id: issue.id,
        type: issue.type,
        workOrder: issue.workOrder,
        summary: issue.summary,
        severity: issue.severity,
      };
      await api.updateIssue(update);
      await refresh();
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  function doEditSelectedIssues(operation: 'approved' | 'unapproved' | 'deleted') {
    setSelectedOperation(operation);
    if (operation === 'approved') {
      setConfirmInfo({ title: 'Confirm Approval', message: `You have selected ${selected.size} issues. Are you sure you want to approve these records.` });
    } else if (operation === 'unapproved') {
      setConfirmInfo({ title: 'Confirm Unapproval', message: `You have selected ${selected.size} issues. Are you sure you want to unapprove these records.` });
    } else if (operation === 'deleted') {
      setConfirmInfo({ title: 'Confirm Deletion', message: `You have selected ${selected.size} issues. Are you sure you want to delete these records.` });
    }
    setShowConfirmIssuesDialog(true);
  }

  async function doConfirmIssues() {
    setShowConfirmIssuesDialog(false);
    setShowIssuesEditDialog(false);
    const update: UpdateIssuesStatus = {
      ids: [],
      value: selectedOperation,
    };
    selected.forEach((issue) => {
      update.ids.push(issue.id);
    });
    try {
      await api.updateIssuesStatus(update);
      getIssues(projectId, status, dateRange, type, severity);
    } catch (error) {
      setError((error as Error).message ?? 'An unexpected error has occurred');
      setShowError(true);
    }
  }

  useEffect(() => {
    getProjects();
    setIsAdmin(hasAccessToAdmin());
    if (projectId !== undefined && projectId !== loadedProjectId) {
      setLoadedProjectId(projectId);
      setProject(projects.find((p) => p.id === projectId));
      getIssues(projectId, status, dateRange, type, severity);
    }
  });

  return (
    <>
      {isAdmin !== true && (
        <Container as="main" className="App py-4 px-3 mx-auto">
          <AuthHeader />
          <Container>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
          </Container>
          <Footer />
        </Container>
      )}
      {isAdmin === true && (
        <Container as="main" className="App py-4 px-3 mx-auto" fluid>
          <AuthHeader />
          <Row>
            <Col lg={2}></Col>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                <Breadcrumb.Item href={`/admin/observations/${projectId}`}>{project?.name} Observations</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <LeftAdminNav projects={projects} onChange={onProjectChange} collapse={false} projectId={project?.id} />
            </Col>
            <Col lg={10}>
              <h2>Observations for {project?.name}</h2>
              {data !== null && (
                <>
                  <div className="row" style={{ paddingBottom: '10px' }}>
                    <div className="col-sm-1">
                      <button type="button" className="btn btn-primary rounded-pill" aria-label="Refresh" data-bs-toggle="refresh" onClick={() => refresh()}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-clockwise"
                          viewBox="0 0 16 16"
                        >
                          <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"></path>
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="col">
                      <Form.Select aria-label="Status" onChange={changeStatus} style={{ width: '80%', display: 'inline-block', marginLeft: '20px' }}>
                        <option id="unapproved">Unapproved</option>
                        <option id="approved">Approved</option>
                      </Form.Select>
                    </div>
                    <div className="col">
                      <Form.Select aria-label="Type" onChange={changeType}>
                        <option value="">Select a type</option>
                        {observationTypes.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="col">
                      <Form.Select aria-label="Severity" onChange={changeSeverity}>
                        <option value="">Select a severity</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Select>
                    </div>
                    <div className="col">
                      <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onChange={changeDateRange} />
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        type="button"
                        className="rounded-pill"
                        disabled={isMergeButtonDisabled()}
                        onClick={() => setShowMergeDialog(true)}
                        style={{ marginRight: '10px' }}
                      >
                        merge
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        className="rounded-pill"
                        disabled={isButtonsDisabled()}
                        onClick={() => setShowIssuesEditDialog(true)}
                        style={{ marginRight: '10px' }}
                      >
                        edit
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        className="rounded-pill"
                        disabled={isButtonsDisabled()}
                        onClick={() => doEditSelectedIssues('deleted')}
                      >
                        delete
                      </Button>
                    </div>
                  </div>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>
                          <Form.Check ref={checkboxRef} key={'checkbox'} onChange={handleAllChecked} checked={isChecked()} />
                        </th>
                        <th onClick={() => sortTable('id')}>
                          ID <i className={'bi ' + (sortField !== 'id' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('creationDate')}>
                          Created{' '}
                          <i
                            className={'bi ' + (sortField !== 'creationDate' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}
                          ></i>
                        </th>
                        <th onClick={() => sortTable('type')}>
                          Type <i className={'bi ' + (sortField !== 'type' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('severity')}>
                          Severity{' '}
                          <i className={'bi ' + (sortField !== 'severity' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('summary')}>
                          Summary{' '}
                          <i className={'bi ' + (sortField !== 'summary' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('workOrder')}>
                          Work Order{' '}
                          <i className={'bi ' + (sortField !== 'workOrder' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((issue) => (
                        <tr key={`issue-${issue.id}`}>
                          <td>
                            <Form.Check label={''} onChange={(event) => handleChecked(event, issue)} checked={selected.has(issue.id)} />
                          </td>
                          <td>
                            <button className="btn btn-link btn-no-shadow" onClick={() => doEditIssue(issue)}>
                              {issue.id}
                            </button>
                          </td>
                          <td>{`${new Date(issue.creationDate).toLocaleDateString()}  ${new Date(issue.creationDate).toLocaleTimeString()}`}</td>
                          <td>{issue.type}</td>
                          <td>{issue.severity}</td>
                          <td>{issue.summary}</td>
                          <td>{issue.workOrder}</td>
                          <td>
                            {issue.images.map((image, index) => (
                              <button className="btn btn-link btn-no-shadow" key={image.id} onClick={() => doSelectImage(image)}>
                                img{index}
                              </button>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {pages !== null && (
                    <Pagination>
                      <Pagination.First onClick={() => fetchPage(1)} />

                      {pages?.map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => fetchPage(page)}>
                          {page}
                        </Pagination.Item>
                      ))}
                      <Pagination.Last onClick={() => fetchPage(lastPage)} />
                    </Pagination>
                  )}
                  {issues === null && (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Footer />
          <IssueEditForm
            show={showEditModal}
            issue={editIssue}
            onHide={() => setShowEditModal(false)}
            onUpdate={doUpdateEditIssue}
            onDelete={doConfirmDeleteEditIssue}
          />
          <IssueImageView
            show={showImageModal}
            image={selectedImage}
            onHide={() => setShowImageModal(false)}
            onDelete={async () => {
              setShowImageModal(false);
              if (selectedImage !== undefined) {
                console.log('Deleting Image id for Observation id', selectedImage?.id, selectedImage?.observationId);
                try {
                  await api.deleteImage(selectedImage.id, selectedImage.observationId);
                  refresh();
                } catch (error) {
                  setError((error as Error).message ?? 'An unexpected error has occurred');
                  setShowError(true);
                }
              }
            }}
          />
          <IssueConfirmDialog
            show={showDeleteItemConfirmation}
            title="Confirm Deletion"
            message="Are you sure you want to delete this record."
            confirm={doDeleteEditIssue}
            onHide={() => setShowDeleteItemConfirmation(false)}
          />
          <IssuesEditDialog
            show={showIssuesEditDialog}
            onApprove={() => doEditSelectedIssues('approved')}
            onUnApprove={() => doEditSelectedIssues('unapproved')}
            onHide={() => setShowIssuesEditDialog(false)}
          />
          <IssueConfirmDialog
            show={showConfirmIssuesDialog}
            title={confirmInfo.title}
            message={confirmInfo.message}
            confirmName="Confirm"
            confirm={doConfirmIssues}
            onHide={() => setShowConfirmIssuesDialog(false)}
          />
          <IssueMergeDialog
            show={showMergeDialog}
            onHide={() => setShowMergeDialog(false)}
            onMerge={async function (mergeId: string | undefined, deleteSource: boolean): Promise<void> {
              console.log('Merge info', mergeId, deleteSource);
              setShowMergeDialog(false);
              if (mergeId === undefined) {
                console.log('Invalid mergeId');
                setError('Invalid mergeId');
                setShowError(true);
                return;
              }
              try {
                const ids = Array.from(selected.values())
                  .filter((issue) => issue.id !== mergeId)
                  .map((issue) => issue.id);
                await api.mergeIssues({
                  ids,
                  mergeId,
                });
                if (deleteSource) {
                  await api.updateIssuesStatus({
                    ids,
                    value: 'deleted',
                  });
                }
                setSelected(new Map());
                refresh();
              } catch (error) {
                setError((error as Error).message ?? 'An unexpected error has occurred');
                setShowError(true);
              }
            }}
            issues={Array.from(selected.values())}
          />
          <ErrorDialog show={showError} title="Error Occurred" message={error ?? ''} onHide={() => setShowError(false)} />
        </Container>
      )}
    </>
  );
}

export default Observations;
