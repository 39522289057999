import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

export interface LeftAdminNavProps {
  collapse?: boolean;
  projectId?: string;
  projects: Array<{ id: string; name: string }>;
  onChange: (projectId: string) => void;
}

export default function LeftAdminNav(props: LeftAdminNavProps) {
  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(e.target.value);
  }
  return (
    <>
      <Container>
        <ul className="list-unstyled">
          <li>
            <a
              href={`#Toggle`}
              data-bs-toggle="collapse"
              role="button"
              aria-controls={`Toggle`}
              className="btn-toggle"
              aria-expanded={props.collapse === false ? true : false}
            >
              Observations Review
            </a>
            <ul id={`Toggle`} className={props.collapse === false ? 'submenu collapse.show' : 'submenu collapse'}>
              <li>
                <Form.Select aria-label="Projects" onChange={handleChange} value={props.projectId}>
                  <option>Select a project</option>
                  {props.projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </Form.Select>
              </li>
            </ul>
          </li>
        </ul>
      </Container>
    </>
  );
}
