import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import mapboxgl, { Map, Marker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import AuthHeader from '../components/site-header';
import Footer from '../components/footer';
import Owl from '../assets/owl.svg';
import Bulldog from '../assets/bulldog.svg';
import Spinner from '../assets/spinner.svg';
import { Camera } from '../types/camera';
import { ObservationData, SiteDevice } from '../types/device';
import { DeviceContext } from '../context/device-context';
import { Api } from '../services/api';
import CamerasView from '../components/cameras-view';
import TestAlarmDialog from '../components/device/test-alarm-dialog';
import ConfigurationDialog from '../components/device/configuration-dialog';

(mapboxgl as any).accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // eslint-disable-line @typescript-eslint/no-explicit-any

type SortField = 'type' | 'severity' | 'summary' | 'work_order' | 'id' | 'creation_date' | 'modified_date' | 'status';
type SortOrder = 'asc' | 'desc';

export default function DevicePage(): ReactElement {
  const ITEMS_PER_PAGE = 25;
  const api = Api.getInstance();
  const { device } = useParams();
  const deviceContext = useContext(DeviceContext);
  const [siteDevice, setSiteDevice] = useState<SiteDevice>();
  const [cameras, setCameras] = useState<Array<Camera>>([]);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(13);
  const [mapEnabled, setMapEnabled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortField, setSortField] = useState<SortField>('creation_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [issues, setIssues] = useState<Array<ObservationData> | null>(null);
  const [data, setData] = useState<Array<ObservationData> | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<Array<number>>([]);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [showConfigDialog, setShowConfigDialog] = useState(false);

  const getCameraFrameApi = async (camera: Camera) => {
    if (camera.url === undefined) return; // skip cameras that don't have a url.
    try {
      const cameraFrame = await api.getCameraFrame(camera);
      const updatedCameras = cameras?.map((camera) => {
        if (camera.name === cameraFrame.name && camera.device === cameraFrame.device) {
          return {
            name: cameraFrame.name,
            id: cameraFrame.id,
            url: cameraFrame.url,
            captured: cameraFrame.captured,
            device: cameraFrame.device,
            deviceName: cameraFrame.deviceName,
          };
        } else {
          return camera;
        }
      });
      if (updatedCameras !== undefined) {
        setCameras(updatedCameras);
        const deviceState = deviceContext.getDevice(cameraFrame.device);
        if (deviceState !== undefined) {
          deviceState.cameras = updatedCameras?.filter((camera) => camera.device === cameraFrame.device);
        }
      }
    } catch (error) {
      console.error('Error occurred fetching camera frame', error);
      setError((error as Error).message);
    }
  };

  const setupPage = (observations: Array<ObservationData>) => {
    if (ITEMS_PER_PAGE < observations.length) {
      const pagesArray = [];
      const pages = Math.ceil(observations.length / ITEMS_PER_PAGE);
      console.log('Pages calculation', observations.length, ITEMS_PER_PAGE, pages);
      const start = 1;
      const pagesInArray = Math.min(start + 6, pages);
      for (let number = start; number <= pagesInArray; number++) {
        pagesArray.push(number);
      }
      const index = currentPage - 1;
      const last = index + ITEMS_PER_PAGE;
      setData(observations.slice(index, last));
      setPages(pagesArray);
    } else {
      // setLastPage(1);
      setPages([]);
      setData(issues);
    }
  };

  const lastPage = () => {
    if (issues === null) return 0;
    return Math.ceil(issues.length / ITEMS_PER_PAGE);
  };

  const setup = async () => {
    if (device === undefined) {
      return;
    }
    try {
      const data = await api.getDeviceData(device);
      setSiteDevice(data);
      setCurrentPage(1);
      setSortField('creation_date');
      setSortOrder('desc');
      setIssues(data.observations);
      setupPage(data.observations);
      const cameras: Array<Camera> = [];
      data.cameras.forEach((c) => {
        cameras.push({
          name: c.name,
          device: device,
          deviceName: data.deviceName,
          id: c.frame?.id,
          captured: c.frame?.captured,
          url: c.frame?.url,
        });
      });
      setCameras(cameras);
      if (data.data !== undefined && data.data.latitude !== null && data.data.longitude !== null) {
        setLat(data.data?.latitude);
        setLng(data.data.longitude);
        setZoom(16);
        setMapEnabled(true);
      } else if (mapEnabled === false) {
        getTelemetry(device);
      }
    } catch (error) {
      console.error('Error occurred getting site data', error);
    }
  };

  async function getTelemetry(robotName: string) {
    try {
      if (mapEnabled === false) {
        const loc = await api.getTelemetry(robotName);
        const device = deviceContext.getDevice(robotName);
        if (device !== undefined) {
          device.location = loc;
        }
        setLng(loc.gps_lon);
        setLat(loc.gps_lat);
        setZoom(16);
        setMapEnabled(true);
      }
    } catch (error) {
      console.error('Error occurred getting location', error);
      setError((error as Error).message);
    }
  }

  useEffect(() => {
    setup();
    const interval = setInterval(() => {
      cameras?.forEach((camera) => {
        getCameraFrameApi(camera);
      });
    }, 60000);

    //Clearing the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceContext]);

  useEffect(() => {
    if (map.current !== null || mapEnabled === false) return; // initialize map only once
    map.current = new Map({
      container: mapContainer.current ?? 'map',
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom,
    });
    new Marker().setLngLat({ lng, lat }).addTo(map.current);
    map.current?.on('move', () => {
      if (map.current === null) return;
      const curr = map.current;
      setLng(parseFloat(curr.getCenter().lng.toFixed(4)));
      setLat(parseFloat(curr.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(curr.getZoom().toFixed(2)));
    });
  }, [mapEnabled, lat, lng, zoom]);

  function handleSorting(field: string, order: 'asc' | 'desc') {
    if (issues !== null) {
      console.log('sortField', field);
      console.log('sortOrder', order);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sorted = [...issues].sort((a: any, b: any) => {
        if (a[field] === null) return 1;
        if (b[field] === null) return -1;
        if (a[field] === b[field]) return 0;
        // return (a[field] < b[field] ? -1 : 1) * (order === 'asc' ? 1 : -1);
        return (
          a[field].toString().localeCompare(b[field].toString(), 'en', {
            numeric: true,
          }) * (order === 'asc' ? 1 : -1)
        );
      });
      setIssues(sorted);
      const index = currentPage - 1;
      const last = index + ITEMS_PER_PAGE;
      setData(sorted.slice(index, last));
    }
  }

  function fetchPage(page: number, issueList?: Array<ObservationData>) {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    if (issueList !== undefined) {
      setData(issueList?.slice(startIndex, endIndex) ?? null);
    } else {
      setData(issues?.slice(startIndex, endIndex) ?? null);
    }
    setCurrentPage(page);
    if (issues !== null && ITEMS_PER_PAGE < issues.length) {
      const pagesArray = [];
      const pages = Math.ceil(issues.length / ITEMS_PER_PAGE);
      console.log('Pages calculation', issues.length, ITEMS_PER_PAGE, pages);
      let start = page;
      if (page > 3) {
        start = page - 3;
      } else {
        start = 1;
      }
      const pagesInArray = Math.min(start + 6, pages);
      for (let number = start; number <= pagesInArray; number++) {
        pagesArray.push(number);
      }
      setPages(pagesArray);
    }
  }

  function sortTable(field: SortField) {
    const order = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    console.log('order', order, sortOrder);
    console.log('field', field, sortField);
    setSortField(field);
    setSortOrder(order);
    handleSorting(field, order);
  }

  return (
    <Container as="main" className="App py-4 px-3 mx-auto" fluid>
      <AuthHeader />
      <Row>
        <Col lg={2} className="device-left-nav">
          <div className="home-site-item-wrapper">
            <div className="home-site-item" style={{ margin: 'auto auto 10px' }}>
              <img className="home-owl-img" src={siteDevice?.data?.type === 'owl' || siteDevice?.data?.type === undefined ? Owl : Bulldog} alt={device} />
              <div className="home-site-device-name">{device?.toUpperCase()}</div>
            </div>
            <div className="home-site-item-name">{device?.toUpperCase()}</div>
          </div>
          <Container style={{ textAlign: 'center' }}>
            <Row>
              <Col style={{ marginBottom: '10px' }}>
                <Button variant="secondary" style={{ width: '200px' }} onClick={() => setShowTestDialog(true)}>
                  TEST
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="secondary" style={{ width: '200px' }} onClick={() => setShowConfigDialog(true)}>
                  CONFIGURATION
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={10}>
          <Container className="device-alert" style={{ marginBottom: '10px' }}>
            <Row>
              <Col className="device-alert-active">
                <i className="bi bi-exclamation-triangle-fill"></i> ALERT ACTIVE
              </Col>
              <Col className="device-alert-timer">300 Seconds to ALARM</Col>
              <Col className="device-alert-buttons">
                <Button variant="danger">Confirm</Button>
                <Button variant="primary">Ignore</Button>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row style={{ minHeight: '400px' }}>
              <Col lg={6}>
                {cameras !== null && cameras.length > 0 && <CamerasView cameras={cameras}></CamerasView>}
                {cameras?.length === 0 && (
                  <Container style={{ width: '100%', height: '100%', backgroundColor: '#868e96' }}>
                    <div className="align-middle" style={{ paddingTop: '30%', textAlign: 'center', fontSize: '20px', color: 'white' }}>
                      No Cameras for this location.
                    </div>
                  </Container>
                )}
              </Col>
              <Col lg={6}>
                {error !== null && (
                  <Container style={{ width: '100%', height: '100%', backgroundColor: '#868e96' }}>
                    <div className="align-middle" style={{ paddingTop: '30%', textAlign: 'center', fontSize: '20px', color: 'white' }}>
                      Unable to find geolocation data for this location.
                    </div>
                  </Container>
                )}
                {error === null && (
                  <Card id={device}>
                    <div ref={mapContainer} style={{ width: '100%', height: '800px' }} className="text-center">
                      {mapEnabled === false && <img className="spinner" src={Spinner} alt="loading" style={{ marginTop: '20vmin' }} />}
                    </div>
                    <Card.Body>
                      <Card.Text>
                        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                {(data?.length ?? 0) > 0 && (
                  <Table striped bordered hover responsive style={{ marginTop: '10px' }}>
                    <thead>
                      <tr>
                        <th onClick={() => sortTable('creation_date')}>
                          Created{' '}
                          <i
                            className={'bi ' + (sortField !== 'creation_date' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}
                          ></i>
                        </th>
                        <th onClick={() => sortTable('type')}>
                          Type <i className={'bi ' + (sortField !== 'type' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('severity')}>
                          Severity{' '}
                          <i className={'bi ' + (sortField !== 'severity' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('summary')}>
                          Summary{' '}
                          <i className={'bi ' + (sortField !== 'summary' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                        <th onClick={() => sortTable('work_order')}>
                          Work Order{' '}
                          <i className={'bi ' + (sortField !== 'work_order' ? 'bi-arrow-down-up' : sortOrder === 'asc' ? 'bi-arrow-down' : 'bi-arrow-up')}></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((issue) => (
                        <tr key={`issue-${issue.id}`}>
                          <td>{`${new Date(issue.creation_date).toLocaleDateString()}  ${new Date(issue.creation_date).toLocaleTimeString()}`}</td>
                          <td>{issue.type}</td>
                          <td>{issue.severity}</td>
                          <td>{issue.summary}</td>
                          <td>{issue.work_order}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {(issues?.length ?? 0) > ITEMS_PER_PAGE && (
                  <Pagination>
                    <Pagination.First onClick={() => fetchPage(1)} />

                    {pages?.map((page) => (
                      <Pagination.Item key={page} active={page === currentPage} onClick={() => fetchPage(page)}>
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Last onClick={() => fetchPage(lastPage())} />
                  </Pagination>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Footer />
      <TestAlarmDialog device={device} show={showTestDialog} onHide={() => setShowTestDialog(false)} />
      <ConfigurationDialog device={device} show={showConfigDialog} onHide={() => setShowConfigDialog(false)} confirm={() => setShowConfigDialog(false)} />
    </Container>
  );
}
