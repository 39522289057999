import { ReactElement, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AuthHeader from '../components/site-header';
import Footer from '../components/footer';
import Owl from '../assets/owl.svg';
import Bulldog from '../assets/bulldog.svg';
import { useNavigate } from 'react-router-dom';
import { Api } from '../services/api';

interface DeviceData {
  id: string;
  name: string;
  type: 'owl' | 'robot';
  project_id: string;
  project_name: string;
  organization: string;
  location: string | null;
  bounds: string | null;
}

interface SiteData {
  name: string;
}

interface Site {
  siteName: string;
  devices: Array<DeviceData>;
}

export default function Home(): ReactElement {
  const api = Api.getInstance();
  const [sites, setSites] = useState<Array<Site>>([]);
  const history = useNavigate();

  const parseSite = (key: string, data: SiteData): DeviceData => {
    let siteName = '';
    const categoryParts = data.name.split('-').map((name) => name.trim());
    if (categoryParts.length >= 4) {
      siteName = categoryParts[3];
    } else if (categoryParts.length <= 3) {
      siteName = categoryParts[2];
    }
    return {
      id: '',
      name: key,
      type: 'owl',
      project_id: '',
      project_name: siteName,
      organization: '',
      location: null,
      bounds: null,
    };
  };

  useEffect(() => {
    api.getDevicesDataForSite().then((data) => {
      const siteMap = new Map<string, Site>();
      Object.keys(data).forEach((key) => {
        let device = data[key].info as DeviceData | undefined;
        if (device === undefined) {
          device = parseSite(key, data[key].site as SiteData);
        }
        const site = siteMap.get(device.project_name);
        if (site === undefined) {
          const newSite: Site = {
            siteName: device.project_name,
            devices: [device],
          };
          siteMap.set(device.project_name, newSite);
        } else {
          site.devices.push(device);
        }
      });
      const siteArray: Array<Site> = [];
      siteMap.forEach((value, key) => {
        siteArray.push(value);
      });
      siteArray.sort((a, b) => (a.siteName.toLowerCase() > b.siteName.toLowerCase() ? 1 : -1));
      setSites(siteArray);
      console.log(siteMap);
    });
  }, [api]);

  return (
    <Container as="main" className="App py-4 px-3 mx-auto" fluid>
      <AuthHeader />
      <Row>
        <Col lg={12}>
          {sites.map((site) => (
            <>
              <p>{site.siteName}</p>
              <div className="home-site">
                {site.devices.map((device) => (
                  <div className="home-site-item-wrapper">
                    <button onClick={() => history(`/device/${device.name}`)} className="home-site-item btn-no-shadow btn">
                      <img className="home-owl-img" src={device.type === 'owl' ? Owl : Bulldog} alt={device.name} />
                      <div className="home-site-device-name">{device.name.toUpperCase()}</div>
                    </button>
                    <div className="home-site-item-name">{device.name.toUpperCase()}</div>
                  </div>
                ))}
              </div>
            </>
          ))}
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
