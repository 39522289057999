import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface TestAlarmDialogProps extends ModalProps {
  device: string | undefined;
}

function TestAlarmDialog(props: TestAlarmDialogProps) {
  const modelProps: ModalProps = { ...props };
  delete modelProps.device;
  return (
    <Modal {...modelProps} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Generate Test Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Use the options below to generate a test alert.</p>
        <p>Fire & Smoke</p>
        <div className="onst-border-wrapper">
          <Button variant="primary">GENERATE TEST ALERT</Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TestAlarmDialog;
